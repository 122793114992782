export const CommunityList = () => import('../../components/CommunityList.vue' /* webpackChunkName: "components/community-list" */).then(c => wrapFunctional(c.default || c))
export const CommunityOwned = () => import('../../components/CommunityOwned.vue' /* webpackChunkName: "components/community-owned" */).then(c => wrapFunctional(c.default || c))
export const CommunityPinned = () => import('../../components/CommunityPinned.vue' /* webpackChunkName: "components/community-pinned" */).then(c => wrapFunctional(c.default || c))
export const CommunityPopular = () => import('../../components/CommunityPopular.vue' /* webpackChunkName: "components/community-popular" */).then(c => wrapFunctional(c.default || c))
export const CommunityPost = () => import('../../components/CommunityPost.vue' /* webpackChunkName: "components/community-post" */).then(c => wrapFunctional(c.default || c))
export const CommunityQuestionList = () => import('../../components/CommunityQuestionList.vue' /* webpackChunkName: "components/community-question-list" */).then(c => wrapFunctional(c.default || c))
export const CommunityQuestionMember = () => import('../../components/CommunityQuestionMember.vue' /* webpackChunkName: "components/community-question-member" */).then(c => wrapFunctional(c.default || c))
export const CommunityRecommended = () => import('../../components/CommunityRecommended.vue' /* webpackChunkName: "components/community-recommended" */).then(c => wrapFunctional(c.default || c))
export const ImageLock = () => import('../../components/ImageLock.vue' /* webpackChunkName: "components/image-lock" */).then(c => wrapFunctional(c.default || c))
export const WidgetFeaturedCommunity = () => import('../../components/WidgetFeaturedCommunity.vue' /* webpackChunkName: "components/widget-featured-community" */).then(c => wrapFunctional(c.default || c))
export const WidgetTrendingCommunity = () => import('../../components/WidgetTrendingCommunity.vue' /* webpackChunkName: "components/widget-trending-community" */).then(c => wrapFunctional(c.default || c))
export const Bookmarks = () => import('../../components/bookmarks.vue' /* webpackChunkName: "components/bookmarks" */).then(c => wrapFunctional(c.default || c))
export const BulkUploadPhoto = () => import('../../components/bulkUploadPhoto.vue' /* webpackChunkName: "components/bulk-upload-photo" */).then(c => wrapFunctional(c.default || c))
export const DropImage = () => import('../../components/drop-image.vue' /* webpackChunkName: "components/drop-image" */).then(c => wrapFunctional(c.default || c))
export const FloatingBanner = () => import('../../components/floating-banner.vue' /* webpackChunkName: "components/floating-banner" */).then(c => wrapFunctional(c.default || c))
export const FooterBar = () => import('../../components/footer-bar.vue' /* webpackChunkName: "components/footer-bar" */).then(c => wrapFunctional(c.default || c))
export const HeroBanner = () => import('../../components/hero-banner.vue' /* webpackChunkName: "components/hero-banner" */).then(c => wrapFunctional(c.default || c))
export const HeroFeature = () => import('../../components/hero-feature.vue' /* webpackChunkName: "components/hero-feature" */).then(c => wrapFunctional(c.default || c))
export const IndexCarousel = () => import('../../components/indexCarousel.vue' /* webpackChunkName: "components/index-carousel" */).then(c => wrapFunctional(c.default || c))
export const LanguageSelect = () => import('../../components/languageSelect.vue' /* webpackChunkName: "components/language-select" */).then(c => wrapFunctional(c.default || c))
export const MobileNavbar = () => import('../../components/mobile-navbar.vue' /* webpackChunkName: "components/mobile-navbar" */).then(c => wrapFunctional(c.default || c))
export const NavbarWhite = () => import('../../components/navbar-white.vue' /* webpackChunkName: "components/navbar-white" */).then(c => wrapFunctional(c.default || c))
export const Notifications = () => import('../../components/notifications.vue' /* webpackChunkName: "components/notifications" */).then(c => wrapFunctional(c.default || c))
export const Page511 = () => import('../../components/page511.vue' /* webpackChunkName: "components/page511" */).then(c => wrapFunctional(c.default || c))
export const PagesError = () => import('../../components/pagesError.vue' /* webpackChunkName: "components/pages-error" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../components/search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const SidebarDefault = () => import('../../components/sidebar-default.vue' /* webpackChunkName: "components/sidebar-default" */).then(c => wrapFunctional(c.default || c))
export const Tour = () => import('../../components/tour.vue' /* webpackChunkName: "components/tour" */).then(c => wrapFunctional(c.default || c))
export const UploadImage = () => import('../../components/uploadImage.vue' /* webpackChunkName: "components/upload-image" */).then(c => wrapFunctional(c.default || c))
export const AskQuestionAnswerComment = () => import('../../components/ask-question/answerComment.vue' /* webpackChunkName: "components/ask-question-answer-comment" */).then(c => wrapFunctional(c.default || c))
export const AskQuestionNewsRelease = () => import('../../components/ask-question/newsRelease.vue' /* webpackChunkName: "components/ask-question-news-release" */).then(c => wrapFunctional(c.default || c))
export const AskQuestionAvatar = () => import('../../components/ask-question/questionAvatar.vue' /* webpackChunkName: "components/ask-question-avatar" */).then(c => wrapFunctional(c.default || c))
export const AskQuestionComment = () => import('../../components/ask-question/questionComment.vue' /* webpackChunkName: "components/ask-question-comment" */).then(c => wrapFunctional(c.default || c))
export const AskQuestionDetailAction = () => import('../../components/ask-question/questionDetailAction.vue' /* webpackChunkName: "components/ask-question-detail-action" */).then(c => wrapFunctional(c.default || c))
export const AskQuestionLists = () => import('../../components/ask-question/questionLists.vue' /* webpackChunkName: "components/ask-question-lists" */).then(c => wrapFunctional(c.default || c))
export const AskQuestionRelated = () => import('../../components/ask-question/questionRelated.vue' /* webpackChunkName: "components/ask-question-related" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommunityAvatar = () => import('../../components/atoms/CommunityAvatar.vue' /* webpackChunkName: "components/atoms-community-avatar" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommunityBanner = () => import('../../components/atoms/CommunityBanner.vue' /* webpackChunkName: "components/atoms-community-banner" */).then(c => wrapFunctional(c.default || c))
export const AtomsPostAvatar = () => import('../../components/atoms/PostAvatar.vue' /* webpackChunkName: "components/atoms-post-avatar" */).then(c => wrapFunctional(c.default || c))
export const CommunityGallery = () => import('../../components/community/gallery.vue' /* webpackChunkName: "components/community-gallery" */).then(c => wrapFunctional(c.default || c))
export const CommunityNavbar = () => import('../../components/community/navbar.vue' /* webpackChunkName: "components/community-navbar" */).then(c => wrapFunctional(c.default || c))
export const HomeMarketplace = () => import('../../components/home/HomeMarketplace.vue' /* webpackChunkName: "components/home-marketplace" */).then(c => wrapFunctional(c.default || c))
export const HomePopularNews = () => import('../../components/home/HomePopularNews.vue' /* webpackChunkName: "components/home-popular-news" */).then(c => wrapFunctional(c.default || c))
export const HomeTopCommunity = () => import('../../components/home/HomeTopCommunity.vue' /* webpackChunkName: "components/home-top-community" */).then(c => wrapFunctional(c.default || c))
export const HomeTopMarketplace = () => import('../../components/home/HomeTopMarketplace.vue' /* webpackChunkName: "components/home-top-marketplace" */).then(c => wrapFunctional(c.default || c))
export const HomeTopQuestion = () => import('../../components/home/HomeTopQuestion.vue' /* webpackChunkName: "components/home-top-question" */).then(c => wrapFunctional(c.default || c))
export const HomeTopUser = () => import('../../components/home/HomeTopUser.vue' /* webpackChunkName: "components/home-top-user" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommunityCarousel = () => import('../../components/molecules/CommunityCarousel.vue' /* webpackChunkName: "components/molecules-community-carousel" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommunityDropdownSetting = () => import('../../components/molecules/CommunityDropdownSetting.vue' /* webpackChunkName: "components/molecules-community-dropdown-setting" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommunityDropdownSettings = () => import('../../components/molecules/CommunityDropdownSettings.vue' /* webpackChunkName: "components/molecules-community-dropdown-settings" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommunityLink = () => import('../../components/molecules/CommunityLink.vue' /* webpackChunkName: "components/molecules-community-link" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommunityListingCard = () => import('../../components/molecules/CommunityListingCard.vue' /* webpackChunkName: "components/molecules-community-listing-card" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommunitySettings = () => import('../../components/molecules/CommunitySettings.vue' /* webpackChunkName: "components/molecules-community-settings" */).then(c => wrapFunctional(c.default || c))
export const MoleculesContentExistanceHandler = () => import('../../components/molecules/ContentExistanceHandler.vue' /* webpackChunkName: "components/molecules-content-existance-handler" */).then(c => wrapFunctional(c.default || c))
export const MoleculesDiscussionDropdown = () => import('../../components/molecules/DiscussionDropdown.vue' /* webpackChunkName: "components/molecules-discussion-dropdown" */).then(c => wrapFunctional(c.default || c))
export const MoleculesDiscussionInteraction = () => import('../../components/molecules/DiscussionInteraction.vue' /* webpackChunkName: "components/molecules-discussion-interaction" */).then(c => wrapFunctional(c.default || c))
export const MoleculesDiscussionSkeleton = () => import('../../components/molecules/DiscussionSkeleton.vue' /* webpackChunkName: "components/molecules-discussion-skeleton" */).then(c => wrapFunctional(c.default || c))
export const MoleculesLinkItem = () => import('../../components/molecules/LinkItem.vue' /* webpackChunkName: "components/molecules-link-item" */).then(c => wrapFunctional(c.default || c))
export const MoleculesListingCard = () => import('../../components/molecules/ListingCard.vue' /* webpackChunkName: "components/molecules-listing-card" */).then(c => wrapFunctional(c.default || c))
export const MoleculesListingDropdown = () => import('../../components/molecules/ListingDropdown.vue' /* webpackChunkName: "components/molecules-listing-dropdown" */).then(c => wrapFunctional(c.default || c))
export const MoleculesListingSellDialog = () => import('../../components/molecules/ListingSellDialog.vue' /* webpackChunkName: "components/molecules-listing-sell-dialog" */).then(c => wrapFunctional(c.default || c))
export const MoleculesMemberDropdown = () => import('../../components/molecules/MemberDropdown.vue' /* webpackChunkName: "components/molecules-member-dropdown" */).then(c => wrapFunctional(c.default || c))
export const MoleculesMemberItem = () => import('../../components/molecules/MemberItem.vue' /* webpackChunkName: "components/molecules-member-item" */).then(c => wrapFunctional(c.default || c))
export const MoleculesModal = () => import('../../components/molecules/Modal.vue' /* webpackChunkName: "components/molecules-modal" */).then(c => wrapFunctional(c.default || c))
export const MoleculesPendingMemberItem = () => import('../../components/molecules/PendingMemberItem.vue' /* webpackChunkName: "components/molecules-pending-member-item" */).then(c => wrapFunctional(c.default || c))
export const MoleculesPostBody = () => import('../../components/molecules/PostBody.vue' /* webpackChunkName: "components/molecules-post-body" */).then(c => wrapFunctional(c.default || c))
export const MoleculesPostSetting = () => import('../../components/molecules/PostSetting.vue' /* webpackChunkName: "components/molecules-post-setting" */).then(c => wrapFunctional(c.default || c))
export const MoleculesPostUserProfile = () => import('../../components/molecules/PostUserProfile.vue' /* webpackChunkName: "components/molecules-post-user-profile" */).then(c => wrapFunctional(c.default || c))
export const MoleculesSectionHeader = () => import('../../components/molecules/SectionHeader.vue' /* webpackChunkName: "components/molecules-section-header" */).then(c => wrapFunctional(c.default || c))
export const MoleculesTrendingBody = () => import('../../components/molecules/TrendingBody.vue' /* webpackChunkName: "components/molecules-trending-body" */).then(c => wrapFunctional(c.default || c))
export const MoleculesTrendingUserProfile = () => import('../../components/molecules/TrendingUserProfile.vue' /* webpackChunkName: "components/molecules-trending-user-profile" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProfilePicture = () => import('../../components/molecules/profile-picture.vue' /* webpackChunkName: "components/molecules-profile-picture" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCardGallery = () => import('../../components/organisms/CardGallery.vue' /* webpackChunkName: "components/organisms-card-gallery" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommunityCard = () => import('../../components/organisms/CommunityCard.vue' /* webpackChunkName: "components/organisms-community-card" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommunityListings = () => import('../../components/organisms/CommunityListings.vue' /* webpackChunkName: "components/organisms-community-listings" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommunityNew = () => import('../../components/organisms/CommunityNew.vue' /* webpackChunkName: "components/organisms-community-new" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommunityOwned = () => import('../../components/organisms/CommunityOwned.vue' /* webpackChunkName: "components/organisms-community-owned" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommunityRecomended = () => import('../../components/organisms/CommunityRecomended.vue' /* webpackChunkName: "components/organisms-community-recomended" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommunityTrending = () => import('../../components/organisms/CommunityTrending.vue' /* webpackChunkName: "components/organisms-community-trending" */).then(c => wrapFunctional(c.default || c))
export const OrganismsContentSectionDiscussion = () => import('../../components/organisms/Content-SectionDiscussion.vue' /* webpackChunkName: "components/organisms-content-section-discussion" */).then(c => wrapFunctional(c.default || c))
export const OrganismsContentSectionListing = () => import('../../components/organisms/Content-SectionListing.vue' /* webpackChunkName: "components/organisms-content-section-listing" */).then(c => wrapFunctional(c.default || c))
export const OrganismsContentSectionMedia = () => import('../../components/organisms/Content-SectionMedia.vue' /* webpackChunkName: "components/organisms-content-section-media" */).then(c => wrapFunctional(c.default || c))
export const OrganismsContentSectionMember = () => import('../../components/organisms/Content-SectionMember.vue' /* webpackChunkName: "components/organisms-content-section-member" */).then(c => wrapFunctional(c.default || c))
export const OrganismsDiscussionItem = () => import('../../components/organisms/DiscussionItem.vue' /* webpackChunkName: "components/organisms-discussion-item" */).then(c => wrapFunctional(c.default || c))
export const OrganismsLandingPostInteraction = () => import('../../components/organisms/LandingPostInteraction.vue' /* webpackChunkName: "components/organisms-landing-post-interaction" */).then(c => wrapFunctional(c.default || c))
export const OrganismsLinkList = () => import('../../components/organisms/LinkList.vue' /* webpackChunkName: "components/organisms-link-list" */).then(c => wrapFunctional(c.default || c))
export const OrganismsListingGallery = () => import('../../components/organisms/ListingGallery.vue' /* webpackChunkName: "components/organisms-listing-gallery" */).then(c => wrapFunctional(c.default || c))
export const OrganismsListingGalleryCard = () => import('../../components/organisms/ListingGalleryCard.vue' /* webpackChunkName: "components/organisms-listing-gallery-card" */).then(c => wrapFunctional(c.default || c))
export const OrganismsListingItem = () => import('../../components/organisms/ListingItem.vue' /* webpackChunkName: "components/organisms-listing-item" */).then(c => wrapFunctional(c.default || c))
export const OrganismsListingSearch = () => import('../../components/organisms/ListingSearch.vue' /* webpackChunkName: "components/organisms-listing-search" */).then(c => wrapFunctional(c.default || c))
export const OrganismsMenuSlider = () => import('../../components/organisms/MenuSlider.vue' /* webpackChunkName: "components/organisms-menu-slider" */).then(c => wrapFunctional(c.default || c))
export const OrganismsMouseEvent = () => import('../../components/organisms/MouseEvent.vue' /* webpackChunkName: "components/organisms-mouse-event" */).then(c => wrapFunctional(c.default || c))
export const OrganismsPostInteraction = () => import('../../components/organisms/PostInteraction.vue' /* webpackChunkName: "components/organisms-post-interaction" */).then(c => wrapFunctional(c.default || c))
export const OrganismsPostListingCard = () => import('../../components/organisms/PostListingCard.vue' /* webpackChunkName: "components/organisms-post-listing-card" */).then(c => wrapFunctional(c.default || c))
export const OrganismsSectionPinnedPosts = () => import('../../components/organisms/SectionPinnedPosts.vue' /* webpackChunkName: "components/organisms-section-pinned-posts" */).then(c => wrapFunctional(c.default || c))
export const OrganismsTabSlider = () => import('../../components/organisms/TabSlider.vue' /* webpackChunkName: "components/organisms-tab-slider" */).then(c => wrapFunctional(c.default || c))
export const OrganismsTrendingItem = () => import('../../components/organisms/TrendingItem.vue' /* webpackChunkName: "components/organisms-trending-item" */).then(c => wrapFunctional(c.default || c))
export const OrganismsTrendingListingCard = () => import('../../components/organisms/TrendingListingCard.vue' /* webpackChunkName: "components/organisms-trending-listing-card" */).then(c => wrapFunctional(c.default || c))
export const OrganismsFooter = () => import('../../components/organisms/footer.vue' /* webpackChunkName: "components/organisms-footer" */).then(c => wrapFunctional(c.default || c))
export const OrganismsGallery = () => import('../../components/organisms/gallery.vue' /* webpackChunkName: "components/organisms-gallery" */).then(c => wrapFunctional(c.default || c))
export const OrganismsImageUpload = () => import('../../components/organisms/image-upload.vue' /* webpackChunkName: "components/organisms-image-upload" */).then(c => wrapFunctional(c.default || c))
export const OrganismsUserPostInfo = () => import('../../components/organisms/user-post-info.vue' /* webpackChunkName: "components/organisms-user-post-info" */).then(c => wrapFunctional(c.default || c))
export const CommunityComponentsHomeNav = () => import('../../components/community/components/HomeNav.vue' /* webpackChunkName: "components/community-components-home-nav" */).then(c => wrapFunctional(c.default || c))
export const CommunityComponentsSearchDialog = () => import('../../components/community/components/SearchDialog.vue' /* webpackChunkName: "components/community-components-search-dialog" */).then(c => wrapFunctional(c.default || c))
export const CommunityComponentsNotifications = () => import('../../components/community/components/notifications.vue' /* webpackChunkName: "components/community-components-notifications" */).then(c => wrapFunctional(c.default || c))
export const CommunityGalleryAssetsLoginDrawer = () => import('../../components/community/gallery-assets/login-drawer.vue' /* webpackChunkName: "components/community-gallery-assets-login-drawer" */).then(c => wrapFunctional(c.default || c))
export const OrganismsBackupMenuSlider = () => import('../../components/organisms/backup/MenuSlider.vue' /* webpackChunkName: "components/organisms-backup-menu-slider" */).then(c => wrapFunctional(c.default || c))
export const OrganismsGalleryAssetsLoginDrawer = () => import('../../components/organisms/gallery-assets/login-drawer.vue' /* webpackChunkName: "components/organisms-gallery-assets-login-drawer" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
